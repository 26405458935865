import React from "react";
import "./Contact.css";
import ContactForm from "../../components/contactForm/ContactForm";


const Contact = () => {
  return (
    <article className="contactContainer">
     <ContactForm />
    </article>
  );
};

export default Contact;
