import React from "react";
import "./home.css";
// import Hero from "../../components/hero/Hero";
import HeroLandingPage from "../../components/heroLandingPage/HeroLandingPage";
import Container from "../../components/container/Container";
import ContactForm from "../../components/contactForm/ContactForm";

const Home = () => {
  return (
    <main>
      <article className="first-article">
        <HeroLandingPage />
      </article>
      <article id="branding">
        <Container
          Title="Branding That Matters"
          Description="Crafting Unique Identities"
          PrimaryButton="Order Now"
          SecondaryButton="Learn More"
          ImgSrc="/images/branding-02.png"
        />
      </article>
      <article id="printing">
        <Container
          Title="Print. Design. Deliver."
          Description="Professional Graphics for Every Business Need"
          PrimaryButton="Order Now"
          SecondaryButton="Learn More"
          ImgSrc="/images/printing.png"
        />
      </article>
      <article id="signage">
        <Container
          Title="Signage That Speaks"
          Description="Bold, Clear, Impactful"
          PrimaryButton="Order Now"
          SecondaryButton="Learn More"
          ImgSrc="/images/signage.png"
        />
      </article>
      <article id="multimedia">
      <Container 
         Title = "Engage Through Multimedia"
         Description = "Dynamic Content, Lasting Impressions"
         PrimaryButton = "Order Now"
         SecondaryButton = "Learn More"
         ImgSrc = "/images/multimedia.png"  
        />
      </article>
      <article id="media">
      <Container 
         Title = "Media Strategy Experts"
         Description = "Tailored Insights, Proven Results"
         PrimaryButton = "Order Now"
         SecondaryButton = "Learn More"
         ImgSrc = "/images/media.png"  
        />
      </article>
      <article id="contact">
        <ContactForm />
      </article>
    </main>
  );
};

export default Home;
