import "./heroLandingPage.css";
import { motion } from "framer-motion"

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const heroLandingPage = ({ ImgSrc = "/images/camera.png" }) => {
  return (
    <div className="hero">
      <div className="hero-row wrapper">
        <div className="content">
          <motion.div className="textBox" variants={textVariants} initial="initial" animate="animate">
            <motion.div variants={textVariants} className="titleText">
              <motion.h1 variants={textVariants} className="display-1">Amplify Your Voice</motion.h1>
            </motion.div>
            <motion.div variants={textVariants} className="subTitle">
              <motion.span variants={textVariants} className="leadText">
                We craft impactful stories and strategies that elevate your
                brand
              </motion.span>
            </motion.div>
            <motion.div variants={textVariants} class="heroButton">
              {/* <motion.a variants={textVariants} href="/contact" class="button button-primary">
                Learn More About Our Services
              </motion.a> */}
            </motion.div>
          </motion.div>
          <motion.div className="imageBox" initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}>
            <img className="itemImage" src={ImgSrc} alt="Hero" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default heroLandingPage;
