import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import FacebookWhite from "./facebook-white.svg";
// import InstagramWhite from "./instagram-white.svg";
import YouTubeWhite from "./youtube-white.svg";
import WhatsAppWhite from "./whatsapp-white.svg";

const Footer = () => {
  return (
    <article id="footer" className="footer_container">
      <div className="footer-top_wrapper">
        <div className="footer-top wrapper">
          <div className="brand-placard">
            <div className="brand-logo">
              <Link to="/" className="footer-links">
                <img src="./logo_white.png" alt="" />
              </Link>
            </div>
            <div className="brand-community">
              <Link target="_blank" to="https://www.facebook.com/AdnosomEnterprises">
                <img src={FacebookWhite} alt="Facebook" />
              </Link>
              <Link target="_blank" to="https://www.youtube.com/@adnosomTV/featured">
                <img src={YouTubeWhite} alt="YouTube" />
              </Link>
              <Link target="_blank" to="https://wa.me/260977752355">
                <img src={WhatsAppWhite} alt="WhatsApp" />
              </Link>
            </div>
          </div>
          <div className="about">
            <span to="./home" className="menu-item-heading displayText-4">
              About Us
            </span>
            <p className="aboutCopy">
              Adnosom Enterprises, established in 2005, is a Zambian media and
              communications consultancy firm serving a diverse clientele,
              including government entities, NGOs, international organizations,
              and private businesses. We offer a comprehensive range of services
              to enhance your brand and communication strategy.
            </p>
          </div>
          <div className="address">
            <span className="menu-item-heading">Contact Us</span>
            Suite 606, 6th Floor, <br /> INDECO House, Buteko Place,
            <br />
            Lusaka, Zambia. <br /> <strong>Telephone:</strong> +260 977752355
            <br /> <strong>Email:</strong> adnosom@yahoo.com
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <span>Copyright © 2024 Adnosom Enterprises</span>
      </div>
    </article>
  );
};

export default Footer;
