import React from "react";
import "./container.css";
import { motion } from "framer-motion";
import { HashLink } from 'react-router-hash-link';

const Container = ({
  Title = "Explore Your Potential",
  Description = "Unlocking Innovation Together",
  PrimaryButton = "Order Now",
  SecondaryButton = "Learn About This Services",
  ImgSrc = "/images/branding-02.png",
}) => {
  return (
    <div className="containerBox">
      <motion.div
        className="row"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="background-image">
          <img className="bgImage" src={ImgSrc} alt="Service Item" />
        </div>
        <div className="content-wrapper">
          <div className="text">
            <h2 className="item_title">{Title}</h2>
            <p className="item_text">
              <span>
                <strong>{Description}</strong>
                <br />
              </span>
            </p>
          </div>
          <div className="cta-button">
            <HashLink
              to="#contact"
              className="order-now-button button button-primary"
            >
              <span>{PrimaryButton}</span>
            </HashLink>
            {/* <HashLink
              href="/"
              className="learn-more-button button button-secondary"
            >
              <span>{SecondaryButton}</span>
            </HashLink> */}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Container;
