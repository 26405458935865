import React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Home from './pages/Home/Home';
import Footer from './components/footer/Footer';
import About from './pages/About/About';
import Navbar from './components/Navbar/Navbar';
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';
import Contact from './pages/contact/Contact';
import MediaConsultancy from './pages/mediaConsultancy/MediaConsultancy';


const Layout = () => {
  return (
    <div className='app'>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about/',
        element: <About />,
      },
      {
        path: '/services/',
        element: <Services />,
      },
      {
        path: '/portfolio/',
        element: <Portfolio />,
      },
      {
        path: '/contact/',
        element: <Contact />,
      },
      {
        parth:'/mediaconsultancy',
        element: <MediaConsultancy />
      }
    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
