import "./navbar.css";
import { Link } from "react-router-dom";
import SiteLogo from "./logo.png";
import FacebookBlue from "./facebook-blue.svg";
// import InstagramBlue from "./instagram-blue.svg";
import YouTubeBlue from "./youtube-blue.svg";
import WhatsAppBlue from "./whatsapp-blue.svg";
import { motion } from "framer-motion";
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  return (
    <nav>
      <input type="checkbox" id="sidebar-active" />
      <label for="sidebar-active" className="open-sidebar-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          viewBox="0 -960 960 960"
          width="32"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      </label>
      <label id="overlay" for="sidebar-active"></label>

      <div className="links-container">
        <label for="sidebar-active" class="close-sidebar-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            viewBox="0 -960 960 960"
            width="32"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </label>
        <motion.div
          className="logo left"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="./">
            <img src={SiteLogo} alt="Logo" />
          </Link>
        </motion.div>
        <motion.div className="menu-items" initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}>
          <Link to="/">Home</Link>
          <HashLink to="#branding">Branding</HashLink>
          <HashLink to="#printing">Printing</HashLink>
          <HashLink to="#signage">Signage</HashLink>
          <HashLink to="#multimedia">Multimedia</HashLink>
          <HashLink to="#media">Media Consultancy</HashLink>
        </motion.div>
        <motion.div className="social-media-icons" initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}>
          <Link target="_blank" to="https://www.facebook.com/AdnosomEnterprises">
            <img src={FacebookBlue} alt="" />
          </Link>
          <Link target="_blank" to="https://www.youtube.com/@adnosomTV/featured
">
            <img src={YouTubeBlue} alt="YouTube Icon" />
          </Link>
          <Link target="_blank" to="https://wa.me/260977752355">
            <img src={WhatsAppBlue} alt="X Icon" />
          </Link>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;
